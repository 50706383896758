import React from 'react';
import PropTypes from 'prop-types'; 

class SectionHeading extends React.Component {
	render() {
    	return (
            <div className={`section-header ${this.props.alignClass}`}>
               {/* <h2 className={`fGold mb-4 ${this.props.heading === "Oktoberfest 2024" ? 'oktoFont' : ''}`}>{this.props.heading }</h2> */}
               <h3 className={`fGold fw-bold ${this.props.heading === "Oktoberfest 2024" ? '' : ''}`} style={{ display: 'inline', fontWeight: 'bolder' }}>
                  {this.props.heading === "Oktoberfest 2024" ? "OKTOBERFEST AT THE EDGE 2024" : ""}
                </h3>
                <h4 className='fGold' style={{ display: 'inline' }}>{this.props.heading !== "Oktoberfest 2024" ? this.props.heading : ''}</h4>
               <h5 className='fGold mb-4 '>{this.props.heading === "Oktoberfest 2024" ? " POWERED BY HNB CREDIT CARDS": ''}</h5>
               {this.props.subHeading1 ? (
               		<h6>{this.props.subHeading1}</h6>
               		):''
               }
               {this.props.subHeading2 ? (
               		<h6>{this.props.subHeading2}</h6>
               		):''
               }
               {/* <div className='row justify-content-center'> */}
                {this.props.subHeading3 ? (
                    <>
                      {/* <div className='col-5'> */}
                        <h6>{this.props.subHeading3}</h6>
                      {/* </div> */}
                    </>
                  ):''
                }
                {this.props.subHeading4 ? (
                    <>
                    {/* <div className='col-5'> */}
                      <h6>{this.props.subHeading4}</h6>
                    {/* </div> */}
                  </>
                  ):''
                }
               {/* </div> */}
               {this.props.subHeading5 ? (
               		<h6>{this.props.subHeading5}</h6>
               		):''
               }
               {/* <span className="line mt-4"></span> */}
            </div>
		);
	}
}


SectionHeading.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string,
  alignClass: PropTypes.string,
};
SectionHeading.defaultProps = {
  subHeading: '',
  alignClass: 'text-center',
}

export default SectionHeading;