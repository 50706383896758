import axios from "axios";
import { toast } from "react-toastify";
import { resetRecoil } from 'recoil-nexus'
import { userState } from "store/user";

const baseURL = "https://apiwetickets.vision8labs.com/api/";
// const baseURL = "http://localhost:3005/api/";

let state = null;

const getState = () => {
  state = JSON.parse(localStorage.getItem("recoil-persist"));
  return localStorage.getItem("access-token");
};

const getAuthHeader = () => {
  // const state2 = getState();
  // if (state2) {
  //   return {
  //     Authorization: `Bearer ${state2}`,
  //   };
  // }

  return {};
};

const apiHandler = axios.create({
  baseURL,
});

apiHandler.interceptors.request.use((config) => {
  config.headers = { ...config.headers, ...getAuthHeader() };
  return config;
});

const customId = "custom-id-yes";

apiHandler.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {

    if (error.response.status === 401) {
      toast("Authentication failed. Please re-login");
      try {
        resetRecoil(userState)
        localStorage.removeItem("access-token");
        window.location.href = "/login";
      } catch (error) {
      }

      // window.location.href = "/login";
    } else{
      if (error?.response?.data?.errors && Object.values(error?.response?.data?.errors)) {
        toast.error(JSON.stringify(Object.values(error?.response?.data?.errors).toString()));
      } else if(error?.response?.data?.message) {
        toast.error(error?.response?.data?.message);
      }
    }
    return error;
    // if (error.message === "Network Error") {
    //   toast(
    //     "You are offline. Please check your internet connection and try again.",
    //     {
    //       toastId: customId,
    //       type: toast.TYPE.WARNING,
    //       autoClose: false,
    //     }
    //   );
    // }
  }
);

export default apiHandler;
