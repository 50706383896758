import ProductBox from 'components/home/ProductBox';
import { STORAGE_PATH } from 'constant';
import React from 'react';
import { Carousel, Col, Row } from 'react-bootstrap';

const EventsCarousel = ({ pastEvents, history }) => {
  const chunkSize = window.innerWidth < 768 ? 1 : 4;
  const chunks = [];

  // Split the pastEvents array into chunks
  for (let i = 0; i < pastEvents.length; i += chunkSize) {
    chunks.push(pastEvents.slice(i, i + chunkSize));
  }

  const handleMenuOnClick = (product) => {
    // if (product && product.open_type === 'POPUP') {
    //   showModal(
    //     <ProductModal title="Special Menu Cards" image={STORAGE_PATH + "/" + product.image} />,
    //     { centered: true }
    //   );
    // } else if (product && product.open_type === 'URL') {
    //   window.open(product.url);
    // }

    history.push(`/event/${product.id}`);
  };


  return (
    <Carousel interval={3000} controls={false} indicators={false} className="shadow-sm">
  {chunks.map((eventChunk, index) => (
    <Carousel.Item key={index}>
      <div className="d-flex justify-content-center">
        {eventChunk.map((menu, idx) => (
          <div className="w-auto d-flex justify-content-center mx-2" key={idx}>
            <ProductBox
              id={menu.id}
              image={`${STORAGE_PATH}/${menu.image}`}
              onClick={() => handleMenuOnClick(menu)}
              imageClass="product-image card-img-top img-fluid rounded-lg"
              imageAlt="product"
              linkUrl="#"
            />
          </div>
        ))}
      </div>
    </Carousel.Item>
  ))}
</Carousel>

  );
};

export default EventsCarousel;
