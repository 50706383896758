import React from "react";
import { Link, withRouter } from "react-router-dom";
import { Row, Col, Container, Image } from "react-bootstrap";

class Success extends React.Component {
  render() {
    // Get the order_id from the URL query parameters
    const orderId = new URLSearchParams(this.props.location.search).get('order_id');

    // Construct the URL for the order details page
    const orderUrl = `/ord/${orderId}`;

    return (
      <section className="section pt-5 pb-5 osahan-not-found-page">
        <Container>
          <Row>
            <Col md={12} className="text-center pt-5 pb-5">
              <Image style={{ width: 150 }} className="img-fluid" src="/img/icons8-correct-512.png" alt="404" />
              <h1 className="mt-2 mb-2">Payment Success!</h1>
              <p className="mb-1" style={{ fontSize: 15 }}>You have successfully placed your order.</p>
              <p className="mb-5" style={{ fontSize: 18 }}>Your order ID:  <Link to={orderUrl} style={{ color: 'white', marginLeft: '5px', fontWeight: 'bold', textDecoration: 'underline !important' }}>
                {orderId}
              </Link></p>
              <Link className="btn btn-blue btn-lg" style={{ color: 'white' }} to="/">
                Back to Home
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    );
  }
}

export default Success;
